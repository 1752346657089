<!-- <div class="container py-2">
    <div style="display: flex; margin-left: -10px; align-items: center"> 
        <mat-icon class="mr-2" (click)="goBack()">arrow_back</mat-icon>
        <app-bread-crumb></app-bread-crumb>
    </div>
</div> -->
<div style="min-height: calc(100vh - 90px); background: white;">
    <h4>{{csDetails?.title}}</h4>
    <app-tag *ngIf="csDetails?.tag_ids.length>0" [tagsIds]="csDetails?.tag_ids" ></app-tag><br>
    
    <app-right-panel-statement-details *ngIf="csDetails" [role]="'prospect-provider'"></app-right-panel-statement-details>
          
   
    <div class="text-center mt-4">
        <button mat-button color="response" *ngIf="csDetails?.response_status == 'new'" (click)="navigateById('prospect-provider-workbench/create-response/','new')">Create Response</button>
        <button mat-button color="response" *ngIf="csDetails?.response_status == 'draft'"  (click)="navigateById('prospect-provider-workbench/create-response/',csDetails.response_id)">Submit Response</button>
        <button mat-button color="response" *ngIf="csDetails?.response_status == 'submitted'"  (click)="navigateById('prospect-provider-workbench/response-details/',csDetails.response_id)">View Response</button>
    </div>
</div>