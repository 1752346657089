import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {tap } from 'rxjs/operators';
import { makeStateKey, TransferState } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class InnovationService {

  constructor(private http: HttpClient,private router:Router,private transferState: TransferState) { }

  resolve(route: ActivatedRouteSnapshot): any {
    let id = atob(route.params['id']);
    try{
      id = atob(id)
    }catch(e){
      this.router.navigate(["404"])
    }
    let params = `status=${id}`;
    return this.http.get(`innovation/innovation_list_without_files/?${params}`)
  }
  getInnovation(offset: number = 0, end: number = 10, subPortId?: any, searchText?: any, portId?: any, initiative_type?: any, benefits?: any, status?: any, vendor?: any,) {
    let params = `offset=${offset}&end=${end}`;
    if (portId) {
      params = `${params}&portfolio=${portId}`
    } if (subPortId) {
      params = `${params}&portfolio=${subPortId}`
    } if (status) {
      params = `${params}&status=${status}`
    } if (searchText) {
      params = `${params}&search_text=${searchText}`
    }
    if (benefits) {
      params = `${params}&benefits=${benefits}`
    }
    if (vendor) {
      params = `${params}&vendor=${vendor}`
    }
    if (initiative_type) {
      params = `${params}&initiative_type=${initiative_type}`
    }

    return this.http.get(`innovation/innovation_list_without_files/?${params}`)
  }

  getProgram() {
    return this.http.get(`dynamic-form/?form_name=opportunity_setup`)
  }
  getDrawdown() {
    return this.http.get(`innovation/get-idea-drawn-down/`)
  }
  getDrawdownList(id: any) {
    return this.http.get(`innovation/get-idea-drawn-down/?vendor=${id}`)
  }

  getStatusFieldValues() {
    const detailKey = makeStateKey(`idea-status-list`);
    const cachedResponse = this.transferState.get(detailKey, null);
    if (!cachedResponse) {
      return this.http.get('innovation/idea-status-list').pipe(
        tap((res:any) => this.transferState.set(detailKey, res))
      )
    }
    return of(cachedResponse);
  }
  getInitiativeData() {
    const statusKey = makeStateKey('get-initiative-types');
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http.get(`innovation/get-initiative-types`).pipe(
        tap((res:any) => this.transferState.set(statusKey, res))
      )
    }
    return of(cachedResponse);
  }
  getBenefitsFieldValue() {    
    const statusKey = makeStateKey('get-idea-benefits');
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http.get('innovation/get-idea-benefits').pipe(
        tap((res:any) => this.transferState.set(statusKey, res))
      )
    }
    return of(cachedResponse);
  }

  getKeyResultsFieldValues() {
    return this.http.get('opportunity/get-keyresults-list');
  }
  getKeydata() {
    /* return this.http.get(`dynamic-form/`) */
    return this.http.get('dynamic-form/?form_name=opportunity_key_results');
    /* opportunity_key_results */
  }
  getTagFieldValues() { 
    const statusKey = makeStateKey('get-hierarchy-as-tag');
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http.get('opportunity/get-hierarchy-as-tag').pipe(
        tap((res:any) => this.transferState.set(statusKey, res))
      )
    }
    return of(cachedResponse);
  }

  getOpportunityFormData(query: any) {
    const dataKey = makeStateKey(`form_name=${query}`);
    const cachedResponse = this.transferState.get(dataKey, null);
    if (!cachedResponse) {
      return this.http.get(`dynamic-form/?form_name=${query}`).pipe(
        tap((res:any) => this.transferState.set(dataKey, res))
      )
    }
    return of(cachedResponse);
  }

  projectList() {
    return this.http.get('delivery/project-list');
  }

  submitIdeaForm(payload: any) {
    return this.http.post('innovation/add-update-idea/', payload, { responseType: 'blob' });
  }
  updateIdeaForm(payload: any, id: any) {
    return this.http.post('innovation/add-update-idea/?idea_id=' + id, payload, { responseType: 'blob' });
  }

  getInnovationDetails(id: any) {
    return this.http.get('innovation/' + id);
  }

  getSimilarIdeasByInitiative_id(id: any) {
    return this.http.get('innovation/innovation_list_without_files/?initiative_type=' + id);
  }


  getSimilarIdeas() {
    return this.http.get('innovation/innovation_list_without_files');
  }

  getRating(rating: any) {
    return this.http.post('innovation/update-idea-rating/', rating);
  }

  updateComments(data: any, id: any) {
    return this.http.post('innovation/add-update-comments-to-idea/' + id + '/', data);
  }

  deleteCommands(id: any) {
    return this.http.delete('innovation/delete-comment/' + id + '/');
  }

  updateStatus(data: any) {
    return this.http.post('innovation/update-idea-status/', data);
  }


  downloadIdeaFile(payload: any, idea_id: any) {
    return this.http.post(`innovation/idea-file-download/${idea_id}/`, payload, { responseType: 'blob' });
  }

  downloadDocument(id: any, payload: any) {
    return this.http.post(`proposal/proposal-file-download/${id}/`, payload, { responseType: 'blob' });
  }

  updateOKRData(data: any) {
    return this.http.post('innovation/update-idea-key-results/', data);
  }

  /* Innovation Dashboard  */


  getInnovationDashboard(offset: number = 0, end: number = 10, subPortId?: any, portId?: any, program?: any, initiative_type?: any) {
    let params =''; //`offset=${offset}&end=${end}`;
    if (subPortId) {
      params = `${params}&portfolio=${subPortId}`
    }
    if (portId) {
      params = `${params}&portfolio=${portId}`
    }
    if (program) {
      params = `${params}&program=${program}`
    }
    if (initiative_type) {
      params = `${params}&initiative_type=${initiative_type}`
    }
    return this.http.get(`innovation/get-innovation-dashboard-items/?${params}`)
  }


  getTopIdeas(id: any) {
    return this.http.get('innovation/get-innovation-dashboard-items/?benefits=' + id);
  }
  getTopIdeasInitiative(id: any) {
    return this.http.get('innovation/get-innovation-dashboard-items/?initiative_type=' + id);
  }
  getDashboardItems(id: any) {
    return this.http.get('innovation/get-innovation-dashboard-items/?project=' + id);
  }

  SaveInvestment(data: any, vendor_id: any) {
    return this.http.post('innovation/add-update-vendor-investment/' + vendor_id + '/', data);
  }
  updateInvestment(data: any, vendor_id: any, investmentId: any) {
    return this.http.post('innovation/add-update-vendor-investment/' + vendor_id + '/?investment_id=' + investmentId, data);
  }

  deleteInvestment(id: any) {
    return this.http.delete('innovation/delete-vendor-investment/' + id);
  }

}
