import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChallengeWorkbenchService } from 'src/app/shared/services/challenge-workbench.service';

@Component({
    selector: 'app-problem-statement-detail',
    templateUrl: './problem-statement-detail.component.html',
    styleUrls: ['./problem-statement-detail.component.scss']
})
export class ProblemStatementDetailComponent implements OnInit {
    statements: any;
    panelOpenState: boolean = true;
    csId:any;
    csDetails:any;
    constructor(private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private spinner : NgxSpinnerService,
        private cw: ChallengeWorkbenchService,
        
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe((res: any) => {
            this.csId = atob(res.get('id'));
            this.getChallengeStatementById();
        });
    }

    navigateTo(url: any) {
        this.router.navigateByUrl(url)
    }

    getGMapRandomColor() {
        return 'hsla(' + Math.floor(Math.random() * 360) + ', 50%, 25%, 90%)';
    }

    goBack(){
        this.location.back();
    }

    getChallengeStatementById(){
        this.spinner.show();
        this.cw.getChallengeStatementById(this.csId).subscribe((res:any)=>{
            this.spinner.hide();
            res.vendor.forEach((element:any) => {
                element.color = this.getGMapRandomColor();
            });
            res.problem_statement_okr.forEach((element:any) => {
                element.color = this.getGMapRandomColor();
            });
            this.csDetails = res;
            this.cw.setData(this.csDetails);
        })
    }
    
    navigateById(url:any,response_id:any){
        this.router.navigate([url+btoa(this.csId)+'/'+btoa(response_id)]);
      }
}
