import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class PrismService {
  constructor(private http: HttpClient) {}

  getCuratedInsightsList(tagFilterId: any, titleFilterText: any) {
    let filterParam = "";
    filterParam = "tag_ids=" + [tagFilterId] + "&title=" + titleFilterText;
    return this.http.get(`insights/insights/?${filterParam}`);
  }
  getMLCase() {
    return this.http.get("insights/ml-case/");
  }

  uploadCuratedInsightDocument(formData: any) {
    return this.http.post("insights/documents/", formData);
  }
  saveCuratedInsights(data: any) {
    return this.http.post("insights/insights/", data);
  }
  editCuratedInsights(data: any, id: any) {
    return this.http.patch(`insights/insights/${id}/`, data);
  }
  deleteInsight(id: any) {
    return this.http.delete(`insights/insights/${id}/`);
  }
  downloadInsightFile(id: any) {
    return this.http.get(`insights/documents/${id}`, { responseType: "blob" });
  }
  getInsightDocument(docName: any, insightId: any) {
    return this.http.get(
      `insights/documents/download_document/?document=${docName}&insight_id=${insightId}`,
      { responseType: "blob" }
    );
  }
  requestInsight(payload: any) {
    return this.http.post("insights/insights/trigger_email/", payload);
  }
  deleteInsightFile(id: any) {
    return this.http.delete(`insights/documents/${id}`);
  }
  getUser() {
    return this.http.get(`users/user-list/`);
  }

  getSoWMasterList(offSet?: any, end?: any) {
    let params = `offset=${offSet}&end=${end}`;
    return this.http.get(`delivery/contract/?${params}`);
  }
  getAllContractList() {
    return this.http.get(`delivery/contract/`);
  }
  deleteSoWContract(id: any) {
    return this.http.delete(`delivery/contract/${id}`);
  }

  getVendor() {
    return this.http.get(`/delivery/get-complete-vendor-list/`);
  }

  saveSowMaster(payload: any) {
    return this.http.post(`delivery/contract/`, payload);
  }
  patchSowMaster(payload: any, id: any) {
    return this.http.patch(`delivery/contract/${id}/`, payload);
  }
  downloadContractMasterFile(id: any) {
    return this.http.get(`delivery/contract/document/${id}`, {
      responseType: "blob",
    });
  }

  getApplicationMaster(offSet?: any, end?: any) {
    let params = `offset=${offSet}&end=${end}`;
    return this.http.get(`application-master?${params}`);
  }
  getApplicationById(id: any) {
    return this.http.get(`application-master/${id}`);
  }
  updateApplication(formValues: any, id: any) {
    return this.http.patch(`application-master/${id}/`, formValues);
  }

  addApplication(formValues: any) {
    return this.http.post(`application-master/`, formValues);
  }

  deleteApplication(id: any) {
    return this.http.delete(`application-master/${id}`);
  }

  getFilteredApplicationMaster(
    filterName: any,
    filterSysCode: any,
    filterBusinessArea: any,
    filterBusinessOwner: any,
    filterLocation: any,
    filterESA: any,
    filterSME: any,
    filterAppType: any,
    filterTag: any,
    offset: any,
    end: any
  ) {
    const params = `offset=${offset}&end=${end}&search=name:${filterName},application_type:${filterAppType},sys_code:${filterSysCode},business_area:${filterBusinessArea},primary_business_owner:${filterBusinessOwner},physical_location:${filterLocation},primary_esa:${filterESA},primary_sme:${filterSME},tags:${filterTag}`;
    return this.http.get(`application-master?${params}`);
  }
  getCompetencyList(offSet?: any, end?: any, queryparam?: any) {
    let params = `offset=${offSet}&end=${end}`;
    return this.http.get(
      `metrics/people-competency-map/?${params}${queryparam}`
    );
  }

  getCostPool(offSet?: any, end?: any, queryparam?: any) {
    let params = `offset=${offSet}&end=${end}`;
    return this.http.get(`metrics/cost-pool-taxonomy/?${params}${queryparam}`);
  }
  getItTower(offSet?: any, end?: any, queryparam?: any) {
    let params = `offset=${offSet}&end=${end}`;
    return this.http.get(`metrics/it-tower-taxonomy/?${params}${queryparam}`);
  }
  getServiceTaxonomy(offSet?: any, end?: any, queryparam?: any) {
    let params = `offset=${offSet}&end=${end}`;
    return this.http.get(`metrics/service-taxonomy/?${params}${queryparam}`);
  }
  getSolutionTaxonomy(offSet?: any, end?: any, queryparam?: any) {
    let params = `offset=${offSet}&end=${end}`;
    return this.http.get(`metrics/solution-taxonomy/?${params}${queryparam}`);
  }

  getTypesList() {
    return this.http.get("base_value/?type=contract_type");
  }

  getExecutionTypesList() {
    return this.http.get("base_value/?type=contract_execution_type");
  }

  getStatus() {
    return this.http.get("base_value/?type=contract_status");
  }

  getFilteredSowMasterList(
    name: any,
    invoiceType: any,
    vendor: any,
    type: any,
    status: any,
    exec: any,
    owner: any,
    contract: any,
    offSet: any,
    end: any
  ) {
    let params = `offset=${offSet}&end=${end}&search=contract_name:${name},invoice_type:${invoiceType},vendor:${vendor},contract_type:${type},status:${status},execution_type:${exec},owner:${owner},contract_party_portfolio:${contract}`;
    return this.http.get(`delivery/contract/?${params}`);
  }

  getApplicationTypes() {
    return this.http.get("base_value/?type=application_type");
  }

  getMasterhierarchy() {
    return this.http.get("portfolio/");
  }
  createPortfolio(payload: any) {
    return this.http.post("portfolio/", payload);
  }
  createSubPortfolio(portfolioId: any, payload: any) {
    return this.http.post(
      `portfolio/${portfolioId}/create-subportfolio/`,
      payload
    );
  }
  getBaseValue(param: any) {
    return this.http.get(`base_value/?type=${param}`);
  }
  updatePortfolio(portfolioId: any, payload: any) {
    return this.http.patch(`portfolio/${portfolioId}/`, payload);
  }
  getInsightCategory() {
    return this.http.get("base_value/?type=maven_category");
  }
  getTag() {
    return this.http.get(`/tag`);
  }

  getOrgName() {
    return this.http.get("/display-names");
  }
}
