import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RateService {
  constructor(private http: HttpClient) {}

  // getVendorData() {
  // }
  getVendorData(offSet?: any, end?: any, id?: any) {
    let params;
    if (id) {
      params = `offset=${offSet}&index=${end}&vendor_id=${id}`;
    } else {
      params = `offset=${offSet}&index=${end}`;
    }
    return this.http.get(`rate/vendor_rate/list?${params}`);
  }
  getVendorLoc(id: number) {
    return this.http.get(`vendors/${id}/locations`);
  }

  updateVendorData(payload: any, id: any) {
    //return this.http.put(`vendor_rate/update/${id}/', payload);
    return this.http.put(`vendor_rate/update/${id}/`, payload);
  }

  getMarketData(offSet?: any, end?: any) {
    let params = `offset=${offSet}&index=${end}`;
    return this.http.get(`rate/market-rates/?${params}`);
  }

  getFilteredMarketData(offSet?: any, end?: any, title?: any) {
    let params = `offset=${offSet}&index=${end}&search=job_title:${title}`;
    return this.http.get(`rate/market-rates/?${params}`);
  }

  getFilteredVendorData(
    offSet?: any,
    end?: any,
    gdp?: any,
    family?: any,
    exp?: any,
    grade?: any,
    title?: any
  ) {
    let params = `offset=${offSet}&index=${end}&search=job_title:${title},vendor_id:${gdp},job_family:${family},grade:${grade},experienceLvel:${exp}`;
    return this.http.get(`rate/vendor_rate/list?${params}`);
  }

  updateMarketData(payload: any, id: any) {
    //return this.http.put(`vendor_rate/update/${id}/', payload);
    return this.http.put(`market-rates/${id}/`, payload);
  }
}
